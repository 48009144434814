














































import { Component, Vue } from 'vue-property-decorator';
import { GuideStore } from '@/themes/v1/stores/guide.store';
import { getModule } from 'vuex-module-decorators';
import { SsrCtx, WithAsyncData, WithPrefetch } from '@/core/vue.types';
import config from '@/env';
import Breadcrumbs from '../../../components/Breadcrumbs.vue';
import ArticleCard from '../../../components/ArticleCard.vue';
import NeedSomethingElse from '../../../components/NeedSomethingElse.vue';
import { ArticleService } from '@/modules/guide/articles/article.service';
import { resolveFromCtx } from '@/themes/v1/fns/inject-resolver';
import { AppStore } from '@/themes/v1/stores/app.store';
import { universalRedirect } from '@/themes/v1/fns/router';
import Adaptive from '@/themes/v1/components/Adaptive';
import HotNews from '@/themes/v1/components/HotNews.vue';
import hotNewsListBuilder from '@/themes/v1/fns/hot-news-list-builder';
import { HotNewsPreview, NewsPreview } from '@/modules/guide/articles';
import QuestionMark from '@/themes/v1/components/QuestionMark.vue';
import { Section } from '@/modules/guide/environment';

@Component({
  components: {
    NeedSomethingElse,
    ArticleCard,
    Breadcrumbs,
    Adaptive,
    HotNews,
    QuestionMark,
  },
})
export default class NewsSection
  extends Vue
  implements WithAsyncData, WithPrefetch {
  guideStore = getModule(GuideStore, this.$store);
  appStore = getModule(AppStore, this.$store);

  showMore = false;
  loading = false;

  get news(): NewsPreview[] {
    return (this.guideStore.items['useful-articles'] as NewsPreview[]) || [];
  }

  get topNews(): HotNewsPreview[] {
    return hotNewsListBuilder(this.news.slice(0, 3), 1, 3);
  }

  get otherNews(): NewsPreview[] {
    return this.news.slice(3);
  }

  get section(): Section {
    return this.appStore.sections['useful-articles'];
  }

  async asyncData(ctx: SsrCtx): Promise<void> {
    const articleService = resolveFromCtx<ArticleService>(
      'articleService',
      ctx
    );
    const items = await articleService.getLastNews(
      config.game,
      ctx.app.$i18n.locale,
      'useful-articles',
      10
    );
    if (!items.length) {
      universalRedirect(ctx, '');
      return;
    }
    const guideStore = getModule<GuideStore>(GuideStore, ctx.store);
    guideStore.setItems({ segment: 'useful-articles', items });
  }

  async prefetch(): Promise<void> {
    this.guideStore.setSection(this.section);
  }

  loadMore(): void {
    this.loading = true;
    // Yes, We cheat!!!
    setTimeout(() => {
      this.showMore = true;
    }, 600);
  }
}
