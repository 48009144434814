import { Component, Vue, Prop } from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';
import { Devices, UiStore } from '@/themes/v1/stores/ui.store';
import { CreateElement, RenderContext } from 'vue';
// eslint-disable-next-line @typescript-eslint/no-var-requires
const NoSsr = require('vue-no-ssr');

const getDevices = (props: any) => {
  return Object.keys(Devices).filter((x) => props[x]) as Devices[];
};

const checkShow = (devices: Devices[], uiStore: UiStore) => {
  return devices.includes(uiStore.device);
};

@Component({
  functional: true,
} as any)
export default class Adaptive extends Vue {
  @Prop({ type: String })
  placeholderServer?: string;

  @Prop({ type: Boolean })
  xs?: boolean;

  @Prop({ type: Boolean })
  sm?: boolean;

  @Prop({ type: Boolean })
  md?: boolean;

  @Prop({ type: Boolean })
  lg?: boolean;

  @Prop({ type: Boolean })
  xl?: boolean;

  render(ce: CreateElement, ctx: RenderContext) {
    const devices = getDevices(ctx.props);
    const show = checkShow(devices, getModule(UiStore, ctx.parent.$store));
    return ce(
      NoSsr,
      {
        props: {
          placeholder: ctx.props.placeholderServer || '',
        },
      },
      show ? ctx.children : []
    );
  }
}
