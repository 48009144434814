





















import { Component, Vue, Prop } from 'vue-property-decorator';

@Component
export default class Breadcrumbs extends Vue {
  @Prop({ type: Boolean, default: false })
  disableMain!: boolean;

  @Prop({ type: Array, default: () => [] })
  links!: any[];

  @Prop({ type: String, required: true })
  current!: string;

  get normalizeCurrent() {
    let val = this.current + '';
    if (val.length > 40) {
      val = `${val.slice(0, 40)}...`;
    }
    return val;
  }

  onClick(name: string) {
    this.$track('breadcumbs', 'click_breadcumbs_link', name);
  }
}
